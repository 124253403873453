import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.citbcommerce) {
  setAuthToken(localStorage.citbcommerce);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#007c7c",
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {<Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/1688-api"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<MonthlyReport />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
