import {
  BarChartOutlined,
  CalendarOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
//   import { sidebarItems } from "../utils/Sidebars";

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "16px",
    width: "18px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultOpenKeys={["Services"]}
      selectedKeys={[path.toString()]}
    >
      <Menu.Item key={"/"} icon={<HomeOutlined style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>

      <SubMenu key="Services" icon={<BarChartOutlined />} title="Services">
        <Menu.Item
          key={"/1688-api"}
          icon={<CalendarOutlined style={menuStyle} />}
        >
          <Link to={"/1688-api"}>1688 Api</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        key="/SettingOutlined "
        icon={<UserOutlined style={{ fontSize: "14px", width: "18px" }} />}
      >
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={<LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
